/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

@import "./navbar.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Source Sans 3", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.01rem;
}

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

@layer base {
  main {
    @apply bg-steel-50 z-10;
    @apply bg-cover bg-no-repeat relative;
  }

  .ground-1-bg {
    @apply bg-cover bg-no-repeat min-h-[300px] relative;
    background-image: url("/images/Ground-01.svg");
    z-index: -10;
  }

  .ground-2-bg {
    @apply bg-cover bg-no-repeat min-h-[500px] relative -mt-12 xl:-mt-1;
    background-image: url("/images/Ground-02.svg");
    z-index: -5;
  }
}

@layer utilities {
  .section-padding {
    @apply py-40;
    .center {
      @apply px-8 md:px-12 max-w-screen-2xl mx-auto;
    }
  }

  .section-padding-top {
    @apply pt-12 md:pt-20;
    .center {
      @apply px-8 md:px-12 max-w-screen-2xl mx-auto;
    }
  }

  .btn-primary {
    @apply h-fit w-fit;
    @apply bg-primary rounded-md text-white overflow-hidden;

    a {
      @apply flex items-center;
      @apply px-6 py-3.5;
      @apply text-white no-underline font-display text-lg;
    }
  }
  .btn-secondary {
    @apply h-fit w-fit;
    @apply rounded-md text-black;
    @apply shadow-[inset_3px_3px_0_#000000,inset_-3px_-3px_0_#000000];
    @apply hover:text-white hover:fill-white;

    a {
      @apply flex items-center;
      @apply px-6 py-3.5;
      @apply no-underline font-display text-xl;
    }
  }
  .btn-dark-blue {
    @apply h-fit w-fit;
    @apply bg-blue-500 rounded-md text-white overflow-hidden;

    a {
      @apply flex items-center;
      @apply px-6 py-3.5;
      @apply text-white no-underline font-display text-lg;
    }
  }
}

.btn-circle-effect {
  @apply relative;
  @apply transition;
  @apply overflow-hidden cursor-pointer;

  a {
    @apply relative;
    @apply transition duration-[450ms] ease-out;
  }

  .embedded-circle {
    @apply absolute top-[20px] left-[70px];
    @apply w-0 h-0;
    @apply opacity-0 leading-10 rounded-[50%];
    @apply transition-all duration-500 ease-out;
  }

  &:hover .embedded-circle {
    @apply -top-[70px] -left-[70px];
    @apply w-[200%] h-[500%];
    @apply opacity-100;
  }
}

#sponsors .sponsor {
  @apply rounded-[20px] bg-clouded;
  @apply flex flex-col items-center;
  @apply z-[2] mb-8;
  @apply max-w-[600px];

  .sponsor--image {
    @apply w-full h-[257px];
    @apply bg-no-repeat bg-contain bg-center;
    @apply mb-6 mx-auto;
  }
  .sponsor--body {
    @apply w-full font-body text-lg;
  }
}

@media (min-width: 768px) {
  #sponsors .sponsor {
    .sponsor--image {
      @apply h-[217px];
    }
  }

  #sponsors .sponsor:nth-child(-n + 2) {
    @apply w-full;
    .sponsor--image {
      @apply h-[257px];
    }
  }
}

@media (min-width: 1280px) {
  #sponsors .sponsor {
    @apply w-1/3 px-8;
    .sponsor--image {
      @apply h-[217px];
    }
  }

  #sponsors .sponsor:nth-child(-n + 2),
  #sponsors .sponsor:nth-child(3) {
    @apply w-5/12;
    @apply mx-1;
    .sponsor--image {
      @apply h-[257px];
    }
  }
}

#homepage-sponsors .sponsor {
  @apply h-16;
  @apply max-w-[110px] w-full;
  @apply odd:justify-self-end;
  @apply relative;
  @apply hover:scale-105;

  &:nth-child(4n + 2),
  &:nth-child(4n + 1) {
    @apply right-1/4;
  }

  &:nth-child(4n),
  &:nth-child(4n + 3) {
    @apply left-1/4;
  }
}

@media (min-width: 640px) {
  #homepage-sponsors .sponsor {
    &:nth-child(6n + 1) {
      @apply justify-self-center;
      @apply right-1/4;
      @apply left-auto;
    }
    &:nth-child(6n + 2) {
      @apply justify-self-center;
      @apply right-auto;
      @apply -left-1/2;
    }
    &:nth-child(6n + 3) {
      @apply justify-self-start;
      @apply -left-1/2;
    }
    &:nth-child(6n + 4) {
      @apply justify-self-center;
      @apply left-3/4;
    }
    &:nth-child(6n + 5) {
      @apply justify-self-center;
      @apply left-1/2;
    }
    &:nth-child(6n + 6) {
      @apply justify-self-center;
      @apply left-1/4;
    }
  }
}
@media (min-width: 768px) {
  #homepage-sponsors .sponsor {
    @apply h-20;
    @apply max-w-[150px] w-full;
  }
}
@media (min-width: 1024px) {
  #homepage-sponsors .sponsor {
    @apply h-24;
    @apply max-w-[200px] w-full;

    &:nth-child(8n + 1) {
      @apply justify-self-center;
      @apply right-1/4;
      @apply left-auto;
    }
    &:nth-child(8n + 2) {
      @apply justify-self-center;
      @apply right-auto;
      @apply -left-1/2;
    }
    &:nth-child(8n + 3) {
      @apply justify-self-center;
      @apply -left-3/4;
    }
    &:nth-child(8n + 4) {
      @apply justify-self-start;
      @apply -left-3/4;
    }
    &:nth-child(8n + 5) {
      @apply justify-self-center;
      @apply left-3/4;
    }
    &:nth-child(8n + 6) {
      @apply justify-self-center;
      @apply left-1/2;
    }
    &:nth-child(8n + 7) {
      @apply justify-self-end;
      @apply left-1/4;
    }
    &:nth-child(8n + 8) {
      @apply justify-self-center;
      @apply left-1/4;
    }
  }
}

/* Locatins page */

.location-tag {
  @apply font-body text-[12px];
  @apply pt-[6px] pb-[5px] px-[11px] h-full;
  @apply rounded-[15px];
  @apply hover:underline transition-all duration-100;


  @apply [&.active]:relative;
  @apply [&.active]:after:content-[''];
  @apply [&.active]:after:block [&.active]:after:absolute;
  @apply [&.active]:after:h-2 [&.active]:after:w-2;
  @apply [&.active]:after:-top-0.5 [&.active]:after:right-px;
  @apply [&.active]:after:rounded;

  &.exercise-sport {
    @apply bg-[#222F33] text-white;
    @apply hover:bg-blue-500;
    @apply [&.active]:after:bg-red-light;
  }
  &.nature {
    @apply bg-[#3D5259] text-white;
    @apply hover:bg-steel-400;
    @apply [&.active]:after:bg-red-light;
  }
  &.food-drink {
    @apply bg-[#BACED4] text-black;
    @apply hover:bg-steel-100;
    @apply [&.active]:after:bg-red-light;
  }
  &.fun-culture {
    @apply bg-[#B40018] text-white;
    @apply hover:bg-red-darkest;
    @apply [&.active]:after:bg-steel-200;
  }
  &.ope-you-gotta-go {
    @apply bg-[#C34242] text-white;
    @apply hover:bg-red-dark;
    @apply [&.active]:after:bg-steel-200;
  }
  &.shopping {
    @apply bg-[#7EAAB8] text-black;
    @apply hover:bg-steel-200;
    @apply [&.active]:after:bg-red-light;
  }
  &.gf-vegan-options {
    @apply shadow-[inset_1px_1px_0_#000000,inset_-1px_-1px_0_#000000];
    @apply text-black;
    @apply hover:bg-steel-10;
    @apply [&.active]:after:bg-red-light;
  }
  &.reset {
    @apply bg-white text-black;
    @apply shadow-[inset_1px_1px_0_#000000,inset_-1px_-1px_0_#000000];
    @apply hover:bg-gray-100;
  }
}

/* Code of Conduct Page */

#code-of-conduct,
#faq {
  p {
    @apply mb-[20px];
  }

  h2 {
    @apply mb-[20px] font-display text-h2 font-medium;
  }

  h3 {
    @apply font-display text-h2 font-medium mb-[20px];
  }

  h4 {
    @apply font-display text-h3 font-medium mb-[10px];
  }
  p:nth-child(5) {
    @apply mb-[20px];
  }

  ul {
    @apply list-disc pl-[32px] pb-[20px];
    p {
      @apply mb-[0];
    }

    ul {
      @apply pl-[16px];
    }
  }

  ol {
    @apply list-decimal pl-[32px] pb-[20px];
  }

  a {
    @apply underline;
  }
}

.scroll-shadows {
  --bg: #F7F9FA;
  --shadow: rgba(41, 50, 56, 0.3);

  background:
  /* Shadow Cover TOP */
  linear-gradient(var(--bg) 30%, transparent) center top,
  /* Shadow Cover BOTTOM */
  linear-gradient(transparent, var(--bg) 70%) center bottom,
  /* Shadow TOP */
  radial-gradient(farthest-side at 50% 0, var(--shadow), transparent) center top,
  /* Shadow BOTTOM */
  radial-gradient(farthest-side at 50% 100%, var(--shadow), transparent) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
  overscroll-behavior: contain;
  /* see https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ */
}

/* Speakers page */

.speaker-section {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.circular--portrait {
  height: 250px;
  width: 250px;
  overflow: hidden;
  border-radius: 50%;

  @apply aspect-square bg-steel-50;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .circular--portrait {
    height: 112px;
    width: 112px;
  }
}

.speaker-talk {
  @apply text-[14px];
}

/* Speaker page */

.blue-tint {
  @apply mix-blend-luminosity rounded-full hover:mix-blend-normal;
}

/* Nav bar staic elements */

.nav_bar::before {
  display: block;
  content: attr(title);
  font-weight: 800;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}