
.site-nav {
  &.light {
    @apply text-white fill-white;

    .nav_bar a:hover {
      color: inherit;
    }
  }

  &.default {
    @apply text-[#1C1C1C] fill-[#1C1C1C];
  }

  &.homepage {
    @apply text-[#1C1C1C] fill-[#1C1C1C];
    #nav-logo-sm--sn { @apply xl:invisible; }
  }
}